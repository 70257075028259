export default function iconTradeBlue() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2708 5.6665V27.3332M23.2708 27.3332L17.8542 21.9165M23.2708 27.3332L28.6875 21.9165M9.72917 27.3332V5.6665M9.72917 5.6665L4.3125 11.0832M9.72917 5.6665L15.1458 11.0832"
        stroke="#4D4DFF"
        strokeWidth="2.70833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { CustomerApi } from "@/apis";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getCookie } from "cookies-next";
import { useRequiredFields } from "../required-fiels";

export function useCustomer(isCompanyForm?: any) {
  const jwt = getCookie("transfero.crypto.web..token", {});

  const {
    data: customer,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["customer", jwt],
    queryFn: async () => {
      const response = await CustomerApi.getCustomer();
      return response;
    },
    enabled: !!jwt,
    retry: false,
    gcTime: 0,
  });

  const isCompany = useMemo(() => customer?.type === "Company", [customer]);
  const isPrime = useMemo(() => customer?.isPrime, [customer]);
  const isBasicCompany = useMemo(
    () => isCompany && customer?.profileLevel === "Basic",
    [customer]
  );
  const isBasicPerson = customer?.profileLevel === "Basic";

  const { requiredFields, isLoading: isLoadingRequiredFields } =
    useRequiredFields({
      customerType: isCompanyForm || customer?.type,
    });

  const hasTaxId = useMemo(() => {
    return !!customer?.taxId;
  }, [customer]);

  const isBasic = useMemo(() => {
    return !hasTaxId;
  }, [hasTaxId]);

  const isLoggedIn = useMemo(() => {
    return jwt !== undefined;
  }, [jwt, customer]);

  const hasSumsub = useMemo(() => {
    if (requiredFields)
      return requiredFields?.filter((i) => i.name === "sumsub");
  }, [requiredFields]);

  const isPrimeEligible = customer?.isPrimeEligible;

  const hasRequiredFields = useMemo(() => {
    return (
      customer?.profileLevel == "Basic" &&
      requiredFields &&
      requiredFields?.length > 1
    );
  }, [customer, requiredFields, hasSumsub]);

  return {
    customer,
    isLoadingCustomer: isLoading || isLoadingRequiredFields,
    isCompany,
    isPrime,
    isBasicCompany,
    hasTaxId,
    isBasic,
    isLoggedIn,
    refetchCustomer: refetch,
    requiredFields,
    hasRequiredFields,
    hasSumsub,
    isBasicPerson,
    isPrimeEligible,
  };
}

export function iconUserBlue() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.0417 28.6875V25.9792C30.0417 23.4552 28.3154 21.3345 25.9792 20.7331M21.2396 4.70624C23.2247 5.50978 24.625 7.45594 24.625 9.72917C24.625 12.0024 23.2247 13.9486 21.2396 14.7521M23.2708 28.6875C23.2708 26.1636 23.2708 24.9017 22.8585 23.9063C22.3088 22.5791 21.2543 21.5246 19.927 20.9748C18.9316 20.5625 17.6697 20.5625 15.1458 20.5625H11.0833C8.55948 20.5625 7.29755 20.5625 6.30213 20.9748C4.97489 21.5246 3.92041 22.5791 3.37065 23.9063C2.95833 24.9017 2.95833 26.1636 2.95833 28.6875M18.5312 9.72917C18.5312 12.7207 16.1061 15.1458 13.1146 15.1458C10.123 15.1458 7.69791 12.7207 7.69791 9.72917C7.69791 6.73762 10.123 4.3125 13.1146 4.3125C16.1061 4.3125 18.5312 6.73762 18.5312 9.72917Z"
        stroke="#4D4DFF"
        strokeWidth="2.70833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

/* eslint-disable no-unused-vars */
import { Icon } from "@/widgets";
import * as S from "./styles";
import router from "next/router";
import LogoutIcon from "../../../../../public/images/icons/logoutIcon";
import { useEffect, useMemo, useRef } from "react";
import { t } from "i18next";
import { ICustomer } from "@/models";
import { PJLevels, individualLevels } from "@/utils";
import { useCustomerInfo } from "@/hooks";
import { AvailableIconsType } from "@/widgets/Icons";

interface ProfileButtonProps {
  name: string;
  nickname: string;
  openOptions: boolean;
  onClick: (a: boolean) => void;
  collapsed: boolean;
  initials?: string;
  customerLevel: string;
  customer: ICustomer;
}

interface ProfileButtonItemProps {
  subMenuItem: string;
  route: string;
  icon: AvailableIconsType;
  id: string;
  isVisible: boolean;
}

const ProfileButton = ({
  name,
  nickname,
  openOptions,
  onClick,
  collapsed,
  initials,
  customerLevel,
  customer,
}: ProfileButtonProps) => {
  const hasSumsubPF = individualLevels.some((level) => level === customerLevel);
  const hasSumsubPJ = PJLevels.some((level) => level === customerLevel);
  const { hasTaxId, isCompany, isBasicIndividual } = useCustomerInfo();

  const subMenuOptions = useMemo(() => {
    const options: ProfileButtonItemProps[] = [
      {
        subMenuItem: t("loggedInMenu.profile"),
        route: "/userprofile",
        icon: "icoUserMenu",
        id: "data-test-user-menu",
        isVisible: !isBasicIndividual,
      },
      {
        subMenuItem: t("changeCurrency.changeCurrency"),
        route: "/changecurrency",
        icon: "icoUserCurrency",
        id: "data-test-user-currency",
        isVisible: true,
      },
      {
        subMenuItem: t("loggedInMenu.changePassword"),
        route: "/changepassword",
        icon: "iconKey",
        id: "data-test-change-password",
        isVisible: true,
      },
      {
        icon: "icoUserMenu",
        route: "/onboarding",
        subMenuItem: t("operationLayout.finishOnboarding"),
        id: "data-test-user-menu",
        isVisible: !hasTaxId,
      },
      {
        icon: "lockKeyholeCircle",
        route: "/changetransactionpassword",
        subMenuItem: t("changePassword.changeTransactionPasswordTitle"),
        id: "data-test-changeTransactionPassword",
        isVisible: hasTaxId,
      },
      {
        subMenuItem: t("sumsub.title"),
        route: "/sumsub",
        icon: "arrowUpNew",
        id: "data-test-sumsub",
        isVisible:
          (hasSumsubPF && !isCompany && hasTaxId) ||
          (hasSumsubPJ && isCompany && hasTaxId),
      },
      {
        icon: "cancelIcon",
        route: "/cancelaccount",
        subMenuItem: t("cancelAccount.cancelAccount"),
        id: "data-test-changeTransactionPassword",
        isVisible: true,
      },
    ];

    return options.filter((item) => item.isVisible);
  }, [isCompany, hasTaxId, hasSumsubPF, hasSumsubPJ]);

  const redirectUser = (route: string) => {
    router.push(route);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onClick(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      {customer && (
        <S.MainContainer ref={dropdownRef}>
          <S.ButtonContainer
            $collapsed={collapsed}
            onClick={() => onClick(!openOptions)}
          >
            <S.ContentInitial>
              <S.StyledIcon>{initials}</S.StyledIcon>
            </S.ContentInitial>

            {!collapsed && (
              <>
                <S.InfoUser $collapsed={collapsed}>
                  <h1>{name || customer?.email || customer?.phoneNumber}</h1>
                  {customerLevel !== "Basic" && <p>{nickname}</p>}
                </S.InfoUser>
                <S.Arrow $open={openOptions}>
                  <Icon icon="arrowAside" />
                </S.Arrow>
              </>
            )}
          </S.ButtonContainer>
          <S.Cnt>
            {openOptions && !collapsed && (
              <S.SubMenuContainer $open={openOptions}>
                {subMenuOptions.map((item) => (
                  <>
                    {item.isVisible && (
                      <S.SubMenuItem
                        key={item.subMenuItem}
                        id={item.id}
                        onClick={() => {
                          onClick(false);
                          redirectUser(item.route);
                        }}
                      >
                        <Icon icon={item.icon} /> {item.subMenuItem}
                      </S.SubMenuItem>
                    )}
                  </>
                ))}

                <S.SubMenuItem
                  onClick={() => {
                    onClick(false);
                    redirectUser("/auth/logout");
                  }}
                >
                  <LogoutIcon /> {t("loggedInMenu.logout")}
                </S.SubMenuItem>
              </S.SubMenuContainer>
            )}
          </S.Cnt>
        </S.MainContainer>
      )}
    </>
  );
};

export default ProfileButton;

import { FeatureFlagFactory } from "@/main/factories/feature-flag";
import { useCustomer } from "@/store/features/customer/customer-query";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { generateAbility } from "../libs/casl/config";
import { FeatureFlag } from "@/domains/models";
import { AppAbility } from "../libs/casl/context";
import { createMongoAbility } from "@casl/ability";

export function useFeatureFlags() {
  const { customer, isLoadingCustomer, hasRequiredFields, hasSumsub } =
    useCustomer();

  const { data: featureFlags, isLoading } = useQuery<FeatureFlag>({
    queryKey: ["feature-flags", customer?.type],
    queryFn: async () => {
      return await FeatureFlagFactory.getFeatureFlag({
        clientType: "TransferoWeb",
        customerType: customer?.type || "Unknown",
      });
    },
    enabled: !!customer,
    retry: false,
  });

  const abilities: AppAbility = useMemo(() => {
    if (!featureFlags) return createMongoAbility<AppAbility>();

    return createMongoAbility<AppAbility>(
      generateAbility(featureFlags, hasRequiredFields)
    );
  }, [featureFlags]);

  const isLoadingAll = useMemo(() => {
    return isLoadingCustomer || isLoading;
  }, [isLoadingCustomer, isLoading]);

  return {
    featureFlags,
    isLoading,
    abilities,
    isLoadingAll,
    customer,
    isLoadingCustomer,
    hasRequiredFields,
    hasSumsub,
  };
}

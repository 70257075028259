export function currencyBitcoinCircle() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1146 10.4064H18.8698C20.5525 10.4064 21.9167 11.7705 21.9167 13.4533C21.9167 15.136 20.5525 16.5002 18.8698 16.5002H13.1146H19.5469C21.2296 16.5002 22.5937 17.8643 22.5937 19.547C22.5937 21.2298 21.2296 22.5939 19.5469 22.5939H13.1146M13.1146 10.4064H11.0833M13.1146 10.4064V22.5939M13.1146 22.5939H11.0833M13.7917 8.37516V10.4064M13.7917 22.5939V24.6252M17.8542 8.37516V10.4064M17.8542 22.5939V24.6252M30.0417 16.5002C30.0417 23.979 23.9789 30.0418 16.5 30.0418C9.02114 30.0418 2.95833 23.979 2.95833 16.5002C2.95833 9.02131 9.02114 2.9585 16.5 2.9585C23.9789 2.9585 30.0417 9.02131 30.0417 16.5002Z"
        stroke="#4D4DFF"
        strokeWidth="2.70833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

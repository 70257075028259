import { api } from "@/apis/axios";
import { useQuery } from "@tanstack/react-query";

type IRequiredFields = {
  name: string;
  status: string;
}[];

type UserRequiredFields = {
  customerType?: string;
};

export function useRequiredFields({ customerType }: UserRequiredFields) {
  const { data: requiredFields, isLoading } = useQuery<IRequiredFields>({
    queryKey: ["required-fields", customerType],
    queryFn: async () => {
      const type = customerType === "Company" ? "/Company" : "";

      const { data } = await api.get<IRequiredFields>(
        `customer/required-fields${type}`
      );
      return data || [];
    },
    enabled: !!customerType,
  });

  return {
    requiredFields,
    isLoading,
  };
}
